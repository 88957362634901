import { Box, Center, Flex, Text } from "@chakra-ui/react";
import {
  ItineratorItineraryList,
  itineraryApi,
} from "@itinerator/next-package";
import { IconChevronRight } from "@tabler/icons-react";
import { AnimatePresence, motion } from "framer-motion";
import { get } from "lodash";
import Head from "next/head";
import { useEffect, useRef, useState } from "react";
import Swiper from "swiper";
import { FreeMode, Keyboard, Mousewheel } from "swiper/modules";
import { Swiper as ReactSwiper, useSwiper } from "swiper/react";
import { useWindowSize } from "usehooks-ts";
import {
  FifthPanel,
  FirstPanel,
  FooterPanel,
  FourthPanel,
  SecondPanel,
  SixthPanel,
  ThirdPanel,
} from "~/components/Home";
import { useIsDesktop } from "~/lib/hooks";
import { GetStrapiServerSideProps, PageProps, getStrapiPage } from "~/strapi";
import { NextPageWithLayout } from "~/types";
type Props = PageProps<{
  itineraries: ItineratorItineraryList;
}>;

const Home: NextPageWithLayout<Props> = ({ itineraries, pageData }: Props) => {
  const logo = get(
    pageData,
    "seo.metaImage.data.attributes.url",
    "https://hwy3bc.ca/meta_image.jpg"
  );
  const size = useWindowSize();
  return (
    <>
      <Head>
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify({
              "@context": "http://schema.org",
              "@type": "Organization",
              name: "Highway 3",
              url: "https://hwy3bc.ca",
              logo,
            }),
          }}
        />
      </Head>
      <Box
        as={ReactSwiper}
        grabCursor
        direction="horizontal"
        freeMode
        mousewheel
        keyboard
        modules={[FreeMode, Mousewheel, Keyboard]}
        slidesPerView={"auto"}
        backgroundColor="gray.900"
        position={"relative"}
        resistanceRatio={0}
        {...size}
      >
        <SwiperArrow />
        <Box display="none">
          <h1>
            Highway 3, get tips on all the hidden gems and stories, and find
            inspiration from our themed road trips as you plan your next
            adventure!  
          </h1>
        </Box>
        <FirstPanel />
        <SecondPanel />
        <ThirdPanel />
        <FourthPanel itineraries={itineraries} />
        <FifthPanel />
        <SixthPanel />
        <FooterPanel />
      </Box>
    </>
  );
};

Home.layoutOptions = { showFooter: false };

export const getServerSideProps: GetStrapiServerSideProps<Props> = async (
  context
) => {
  const pageData = await getStrapiPage({ id: "home" });
  const itineraries = await itineraryApi.getItineraries();
  return { props: { pageData, itineraries } };
};

export default Home;

const SwiperArrow = () => {
  const isDesktop = useIsDesktop();
  const position = useSwiperPosition();
  return (
    <Flex
      alignItems="center"
      gap={5}
      position="fixed"
      bottom={{ base: 5, md: "5vh" }}
      left={"5vw"}
      zIndex={10}
    >
      <AnimatePresence>
        {position !== "end" && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0, transition: { duration: 0.5 } }}
            transition={{ duration: 6 }}
          >
            <Center
              backgroundColor={"#F7971E"}
              height={14}
              width={14}
              borderRadius="full"
            >
              <IconChevronRight size={40} stroke={1.5} />
            </Center>
          </motion.div>
        )}
      </AnimatePresence>
      <AnimatePresence>
        {position === "beginning" && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0, transition: { duration: 0.5 } }}
            transition={{ duration: 6 }}
          >
            <Text
              fontSize={{ base: "xl", md: "3xl" }}
              color="black"
              flexWrap="wrap"
              as="span"
            >
              {`${isDesktop ? "Scroll" : "Swipe"} Map to Explore`}
            </Text>
          </motion.div>
        )}
      </AnimatePresence>
    </Flex>
  );
};

type Position = "beginning" | "middle" | "end";

const useSwiperPosition = () => {
  const swiper = useSwiper();
  const [position, setPositionState] = useState<Position>("beginning");
  const positionRef = useRef<Position>("beginning");
  const lastScrollPosition = useRef<number>(0);
  useEffect(() => {
    const setPosition = (pos: Position) => {
      setPositionState(pos);
      positionRef.current = pos;
    };
    const setBeginning = () => {
      lastScrollPosition.current = 0;
      setPosition("beginning");
      swiper.on("setTranslate", setMiddle);
    };
    const setEnd = (swiper: Swiper) => {
      lastScrollPosition.current = swiper.translate;
      setPosition("end");
      swiper.on("setTranslate", setMiddle);
    };
    const setMiddle = (s: Swiper, t: number) => {
      const movingRight = t < lastScrollPosition.current;
      const movingLeft = t > lastScrollPosition.current;
      lastScrollPosition.current = t;
      if (
        (movingRight && positionRef.current === "beginning") ||
        (movingLeft && positionRef.current === "end")
      ) {
        swiper.off("setTranslate", setMiddle);
        setPosition("middle");
      }
    };
    if (swiper) {
      swiper.on("reachBeginning", setBeginning);
      swiper.on("reachEnd", setEnd);
      swiper.on("setTranslate", setMiddle);
    }
    return () => {
      swiper.off("reachBeginning", setBeginning);
      swiper.off("reachEnd", setEnd);
      swiper.off("setTranslate", setMiddle);
    };
  }, [swiper]);
  return position;
};
