import { Box, Flex, Text } from "@chakra-ui/react";
import { motion } from "framer-motion";
import { SwiperSlide } from "swiper/react";
import home from "~/images/home.jpeg";
import { Logo } from "~/images/svgs";
import { RoadSvg } from "../RoadSvg";

export const FirstPanel = () => {
  return (
    <Box
      as={SwiperSlide}
      h="auto"
      display="flex"
      flexDirection="column"
      alignContent="flex-end"
      justifyContent="flex-end"
      backgroundImage={`url(${home.src})`}
      backgroundSize={"cover"}
      backgroundPosition="center"
      padding={{ base: "64px 32px", md: "104px", lg: "32px 104px" }}
    >
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 6 }}
      >
        <Flex
          flexDir="column"
          justifyContent={{ base: "flex-end", md: "space-between" }}
        >
          <Box w={{ base: "320px", md: "420px", lg: "70%" }}>
            <Logo
              h={{ base: "30px", lg: "50px" }}
              mb={{ base: "24px" }}
              color={{ base: "black", lg: "black" }}
            />
            <Text
              w={{ base: "80vw" }}
              fontSize={{ base: "5xl", md: "8xl" }}
              fontWeight="thin"
              color="black"
              flexWrap="wrap"
              as="span"
            >
              The winding route to{" "}
            </Text>
            <Text
              fontSize={{ base: "5xl", md: "8xl" }}
              color="black"
              fontWeight="bold"
              as="span"
            >
              adventure
            </Text>
          </Box>

          <Box mt={{ base: "-40px", md: 0 }}>
            <RoadSvg />
          </Box>
        </Flex>
      </motion.div>
      <Box id="container" />
    </Box>
  );
};
FirstPanel.displayName = "SwiperSlideFirstPanel";
